import styled from 'styled-components';

export const StyleAccordion = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const StyleAccordionButton = styled.div`
  all: unset;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  border-top: 1px solid rgba(178, 187, 198, .3);
  padding: 12px 16px;
  cursor: pointer;
  
  &:first-child {
    border-top: none;
  }
  
  h2 {
    color:  #1D242D;
    font-size: 1.143rem;
    font-weight: 700;
  }
`;

export const StyleQuantity = styled.div`
  display: flex;
  height: 14px;
  min-width: 14px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #1D242D;
  border-radius: 100px;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;
