/**
 * @description
 * Popup component that can be used to display a floating element relative to a trigger element.
 * Part of Selector and Dropdown components.
 */
import {
  useFloating,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useDismiss,
  autoPlacement,
  autoUpdate,
  offset, flip, hide, size, Placement,
} from '@floating-ui/react';
import {
  CSSProperties, forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo, useState,
} from 'react';
import { BasePopup } from '../base';
import { StyledTrigger } from './styles';
import { PopupLayout } from './PopupLayout';
import { borderRadius } from '@mui/system';

/**
 * Strategy for positioning the popup when there is not enough space.
 * no-space — Ensures the preferred placement is kept unless there is no space left.
 * most-space — Always chooses the placement with the most space available.
 * ignore-space — Ignores the space and always chooses the preferred placement.
 */
type SpaceStrategy = 'no-space' | 'most-space' | 'ignore-space'

const getSpaceStrategy = (strategy: SpaceStrategy, allowedPlacements?: Placement[]) => {
  const strategyList = [];
  if (strategy === 'most-space') {
    strategyList.push(autoPlacement({ allowedPlacements }));
  } else if (strategy === 'no-space') {
    strategyList.push(flip({ fallbackPlacements: allowedPlacements }));
  }

  return strategyList;
};

/**
 * trigger - ReactNode that will be used as a trigger (button) for the popup.
 * children - ReactNode that will be displayed inside the popup.
 * onTrigger - Function that will be called when the trigger is clicked.
 * placement - Placement of the popup relative to the trigger.
 * allowedPlacements - List of allowed placements for the popup.
 * onClose - Function that will be called when the popup is closed.
 * offsetX - Horizontal offset of the popup.
 * offsetY - Vertical offset of the popup.
 * hideOffset - Offset for the hide strategy.
 * spaceStrategy - Strategy for positioning the popup when there is not enough space.
 * stretchToReference - If true, the popup will stretch to the width of the reference element.
 * width - Width of the popup.
 * stretchToViewportY - If true, the popup will stretch to the height of the viewport.
 * viewportOffsetY - Offset for the viewport height.
 * withPortal - If true, the popup will be rendered inside a portal.
 * lazy - If true, the popup will be rendered only when it is open.
 * borderRadius - Border radius of the popup.
 */
export interface Props {
  trigger: ReactNode
  children: ReactNode
  onTrigger?: () => void
  placement?: Placement
  allowedPlacements?: Placement[]
  onClose?: () => void
  offsetX?: number
  offsetY?: number
  hideOffset?: number
  spaceStrategy?: SpaceStrategy
  stretchToReference?: boolean
  width?: number
  stretchToViewportY?: boolean
  viewportOffsetY?: number
  withPortal?: boolean
  lazy?: boolean
  borderRadius?: number
}

export type PopupRef = {
  onClose(): void
  onOpen(): void
}

export const Popup = forwardRef(({
  children,
  trigger,
  allowedPlacements = ['top-start', 'bottom-start'],
  offsetY = 4,
  offsetX = 0,
  hideOffset = 0,
  placement = 'bottom-start',
  spaceStrategy = 'most-space',
  stretchToReference = false,
  stretchToViewportY = false,
  viewportOffsetY = 0,
  width,
  withPortal = true,
  lazy = false,
  borderRadius,
}: Props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset({
        mainAxis: offsetY,
        crossAxis: offsetX,
      }),
      hide({
        strategy: 'referenceHidden',
        padding: hideOffset,
      }),
      size({
        apply({ rects, elements, availableHeight }) {
          if (stretchToReference) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          }

          if (stretchToViewportY) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight - viewportOffsetY}px`,
            });
          }
        },
      }),
      ...getSpaceStrategy(spaceStrategy, allowedPlacements),
    ],
  });

  const onClose = useCallback(() => setIsOpen(false), []);

  const onOpen = useCallback(() => setIsOpen(true), []);

  useImperativeHandle(ref, () => ({
    onClose,
    onOpen,
  }));

  // TODO: maybe we could do better than this
  const styles = useMemo(() => ({
    ...floatingStyles,
    visibility: middlewareData?.hide?.referenceHidden ? 'hidden' : 'visible',
  }) as CSSProperties, [floatingStyles, middlewareData?.hide?.referenceHidden]);

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  return (
    <PopupLayout
      isOpen={isOpen}
      isLazy={lazy}
      withPortal={withPortal}
      trigger={(
        <StyledTrigger
          ref={refs.setReference}
          {...getReferenceProps({ onClick: (e) => e.stopPropagation() })}
        >
          {trigger}
        </StyledTrigger>
      )}
    >
      <FloatingFocusManager context={context} modal initialFocus={refs.floating}>
        <BasePopup
          $isVisible={isOpen}
          onClick={(e) => e.stopPropagation()}
          ref={refs.setFloating}
          style={styles}
          {...getFloatingProps()}
          $width={width}
          data-testid="popupContainer"
          $radius={borderRadius}
        >
          {children}
        </BasePopup>
      </FloatingFocusManager>
    </PopupLayout>
  );
});
