import type { FC } from 'react';
import { useMemo } from 'react';
import { Image, Picture as StyledPicture } from './styles';

interface Props {
  src: string
  alt?: string
  isLazy?: boolean
  objectFit?: 'contain' | 'cover'
}

export const Picture: FC<Props> = ({ src, alt = '', isLazy = true, objectFit = 'contain' }) => {
  const source = useMemo(() => {
    // TODO: remove fallback after changing all images
    if (!src.startsWith('http') && src.includes('.')) {
      return src.split('.')[0];
    }
    return src;
  }, [src]);

  const isBlob = useMemo(() => {
    const prefix = src.split(':', 1)[0];
    return prefix === 'blob';
  }, [src]);

  if (isBlob) {
    return <Image src={src} alt={alt} loading={isLazy ? 'lazy' : 'eager'} $objectFit={objectFit} />;
  }

  return (
    <StyledPicture>
      <Image src={source} alt={alt} loading={isLazy ? 'lazy' : 'eager'} $objectFit={objectFit} />
    </StyledPicture>
  );
};
