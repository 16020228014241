import { ChangeEvent, FC, InputHTMLAttributes, ReactNode, useMemo } from 'react';
import { TrashBin, Plus, Trolley } from '@gravity-ui/icons';
// eslint-disable-next-line object-curly-newline
import { FileInput, Wrapper, FileStyle, TextStyle, HoverIcon, CountStyle, PictureBlock } from './styles';
import { GlobalColors } from '../GlobalStyle';
import { Person } from './person';
import { getPersonSize, getIconSize, getShortName } from './helpers';
import { Picture } from './Picture';

export type ImgSizeType = 16 | 24 | 28 | 32 | 36 | 64 | 120;
export type ColorStyleType = 'default' | 'success' | 'danger' | 'disabled' | 'error';

export interface AvatarProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  /**  avatar - user photos / pic - catalog images */
  type?: 'user' | 'pic'
  size?: ImgSizeType
  src?: string
  alt?: string
  /**  name two first  letters  */
  name?: string
  /**  for no src pic type  */
  customIcon?: ReactNode
  /** catalog updates count / for pic type only / for sizes 24 | 28 | 32 | 36 */
  badgeCount?: number
  colorStyle?: ColorStyleType
  onChange?: (file: File) => void
  /**  reset pic function  */
  onClear?: () => void
  isEditable?: boolean
}

export const Avatar: FC<AvatarProps> = ({
  type = 'user',
  src,
  onChange,
  onClear,
  size = 64,
  alt = '',
  name,
  customIcon,
  colorStyle = 'default',
  badgeCount,
  isEditable = true,
  ...rest
}) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0];

    if (fileObj && onChange) {
      onChange(fileObj);
    }
  };

  const handleFileDelete = () => {
    if (src && onClear && (size === 64 || size === 120)) {
      onClear();
    }
    return null;
  };

  const isBigSize = useMemo(() => (size === 64 || size === 120), [size]);

  return (
    <Wrapper $size={size} $type={type} $isEditable={isEditable}>
      {src
        ? (
          <PictureBlock $type={type} $size={size}>
            <Picture src={src} alt={alt} objectFit="cover" />
            {/* delete picture icon */}
            {type !== 'pic' && isBigSize && isEditable
                && (
                <HoverIcon $bgColor="error" $size={size} tabIndex={0} onClick={handleFileDelete}>
                  <TrashBin width={14} height={14} color={GlobalColors.icon.primaryOnColor} />
                </HoverIcon>
                )}
          </PictureBlock>
        ) : (
          <>
            <FileStyle $colorStyle={colorStyle} $type={type} $size={size}>
              <TextStyle $size={size}>
                {type === 'user'
                  // eslint-disable-next-line max-len
                  ? getShortName(name) || <Person width={getPersonSize(size)} height={getPersonSize(size)} color={colorStyle} />
                  // eslint-disable-next-line max-len
                  : getShortName(name) ?? customIcon ?? <Trolley width={getIconSize(size)} height={getIconSize(size)} color={GlobalColors.icon.primaryOnColor} />}
              </TextStyle>
            </FileStyle>

            {/* upload new picture icon */}
            {type === 'user' && isBigSize && isEditable
                && (
                <HoverIcon $bgColor="default" $size={size} tabIndex={0}>
                  <Plus width={14} height={14} color={GlobalColors.icon.primaryOnColor} />
                  <FileInput
                    {...rest}
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    onChange={handleFileChange}
                  />
                </HoverIcon>
                )}

            {/* updates count */}
            {!isBigSize && !(size === 16) && type === 'pic' && badgeCount
                && <CountStyle $size={size}>{badgeCount}</CountStyle>}
          </>
        )}
    </Wrapper>
  );
};
